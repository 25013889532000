.jsonStringArea{
    height: calc(100vh - 200px);
    overflow-y: scroll;
    background-color: rgba(152, 200, 251, 0.256);
    width: 100%;
}
.jsonStringArea pre{
    width: 100%;
    /* white-space:inherit; */
    text-align: left;
}
.parserTextArea{
    min-width: 100%;
    max-width: 100%;
    height: 600px;
}