.chartOuter{
    padding: 10px;
}
.chartTitle{
    font-weight: bold;
    font-size: 20px;
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
}
.chart{
    border-top: 1px solid black;
    border-left: 1px solid black;
}
.chartRow{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.rowItem{
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    padding: 10px;
    font-size: 14px;
}
.rowItemBold{
    font-weight: bold;
}