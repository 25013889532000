.upcomingEventsArea{
    /* padding: 40px; */
    padding-bottom: 0;
    text-align: left;
}
.eventsPageTitle{
    font-size: 30px;
    font-weight: bold;
    padding-top: 40px;
}
.eventsImg{
    float: right;
    height: 300px;
    width: 400px;
    margin:20px;
    margin-right: 0;
    margin-top: 60px;
}
.eventDescription{
    padding: 20px 0;
}
@media screen and (max-width: 800px){
    .eventsImg{
        width: 100%;
        object-fit: cover;
        margin-top: 10px;
    }
}