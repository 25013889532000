.contactPage{
    padding: 40px;
}
.contactPageTitle{
    font-size: 40px;
    font-weight: bold;
    padding-bottom: 5px;
    border-bottom: 2px solid #fc0;
}
.contactAreaText{
    text-align: left;
    padding: 0 40px;
}
.contactAreaTextSmaller{
    padding: 0 40px;
}
.contactAreaText p{
    text-align: justify;
}
.contactArea{
    padding-top: 40px;
    width: 50%;
    max-width: 600px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
}
.contactAreaTitleConteiner{
    width: 100%;
    text-align: center;
}
.contactAreaTitle{
    font-size: 20px;
    font-weight: bold;
    border-bottom: 2px solid #fc0;
    width: 200px;
    padding-bottom: 5px;
    display: inline-block;
}
.contactMethod{
    padding-top: 10px;
}
.contactMethodColumnConteinaesr{
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
}
.contactMethodColumn{
    text-align: center;
}
.contactMethodTitle{
    font-weight: bold;
    padding-left: 5px;
    padding-top: 20px;
}
.contactMethod{
    padding-top: 20px;

}
.contactMethod a{
    vertical-align: top;
}
.copyImageContainer{
    text-align: center;
    width: 30px;
    display: inline-block;
    margin-left: 10px;
}
.copyImage{
    height: 22px;
    width: 22px;
    margin-bottom: -10px;
    cursor: pointer;
}
.copyImageLabel{
    font-size: 8px;
    text-align: center;
    display: inline-block;

}

/* Message Box */

.messageBox{
    width: 100%;
    border: 1px solid black;
    margin: 20px 0;
    padding: 20px;
    height: 530px;
}
.messageBoxTitle{
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 20px;
}
.messageBox input{
    width: 100%;
    border-radius: 2px;
}
.messageBox textarea{
    min-width: 100%;
    max-width: 100%;
    height: 200px;
    border-radius: 2px;
}
.submitMessageButtonArea{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
}
.submitMessageButtonArea button{
    width: 48%;
    display: inline-block;
}

/* Map View */

.mapBox{
    width: 100%;
    border: 1px solid black;
    margin: 20px 0;
    /* padding: 20px; */
    height: 530px;
    /* margin-right: 0 20px; */
}
.mapBoxImg{
    width: 60%;
    height: calc(100% - 200px);
    display: inline-block;
}
.mapBoxImg img{
    object-fit: cover;
}
.mapBoxDescription{
    display: inline-block;
    vertical-align: top;
    padding: 20px;
    padding-top: 30px;
    width: 40%;
    background-color: rgb(230, 232, 238);
    height: calc(100% - 200px);
}
.mapInfo{
    text-align: left;
    padding: 20px;
}
.mapInfoTitle{
    font-weight: bold;
    font-size: 20px;
}
.mapInfoDescription{
    padding: 10px 0;

}
.mapBoxDescriptionName{
    font-weight: bold;
    font-size: 18px;
}
.mapBoxDescriptionAddress{
    font-weight: bold;
    font-size: 12px;
    color: rgb(85, 85, 85);
    padding-top: 20px;
}

@media screen and (max-width: 1100px){
    .mapBoxImg{
        /* object-fit: cover; */
        width: 100%;
        /* height: auto; */
    }
    .mapBoxDescription{
        height: auto;
        width: 100%;
        margin-top: -10px;
        padding: 8px;
        padding-left: 30px;
    }
    .mapBoxDescriptionAddress{
        padding-top: 5px;
    }
    .mapInfoDescription{
        font-size: 12px;
    }
    .mapInfo{
        padding: 5px 10px;
    }
    .mapBoxDescriptionName{
        padding-top: 0;
    }
    .contactPage{
        padding: 40px 20px;
    }
    .contactAreaText{
        width: 95%;
        margin-left: 2.5%;
        padding: 20px 0;
    }
    .contactAreaTextSmaller{
        padding: 20px 20px;

    }
}
@media screen and (max-width: 800px){
    .contactArea{
        width: 100%;
        max-width: 100%;
    }
    .contactAreaText{
        padding: 0 5px;
    }
    .contactAreaTextSmaller{
        padding: 20px 20px;

    }
    .contactPage{
        padding: 40px 0;
    }
    .messageBox{
        height: auto;
    }
    .mapBox{
        height: auto;
    }
}
