.wordCard{
    border-radius: 8px;
    background-color: rgb(86, 80, 90);
    height: 400px;
    width: 300px;
    padding: 6px;
    z-index: 4;
    position: absolute;
    left: calc(50% - 150px);
    top: calc(50% - 200px);
    color: white;
}
.wordCard input, textarea{    
    color: white;
}    
.wordCardInner{
    border-radius: 5px;
    border: 1px solid black;
    height: 100%;
    width: 100%;
    /* padding: 5px; */
    box-sizing: border-box;
}
.wordCardTop{
    border-bottom: 1px solid black;
    font-weight: bold;
    font-size: 20px;
    padding: 2px;
    height: 35px;
    overflow: hidden;
}
.wordCardBottom{
    /* border: 1px solid black; */
}
.wordCardCloseButton{
    position: absolute;
    right: 12px;
    top: 12px;
    background-color: rgba(238, 184, 200, 0.5);
}
.editButton{
    position: absolute;
    top: 2px;
    left: 2px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    border-radius: 5px;  
    border: 1px solid rgb(112, 112, 112);
    user-select: none;
    box-sizing: border-box;
    font-size: 20px;
    transition: 500ms;
  }
  .editButton:hover{
    background-color: rgba(146, 146, 146, 0.5);
  }
.speakButton{
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    border-radius: 5px;  
    border: 1px solid rgb(112, 112, 112);
    user-select: none;
    box-sizing: border-box;
    font-size: 20px;
    transition: 500ms;
  }
  .speakButton:hover{
    background-color: rgba(146, 146, 146, 0.5);
  }