.App {
  text-align: center;

}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.window{
  position: fixed;
  left: 10%;
  top: 100px;
  width: 80%;
  height: calc(100vh - 140px);
  margin: 0;
  background-color: rgb(63, 63, 63);
  overflow: hidden;  
  z-index: 3;
}
.nextClickBox{
  width: calc(100% - 10px);
  height: 100px;
}
.settingsWindow{
  position: fixed;
  left: 10%;
  top: 100px;
  width: 80%;
  height: calc(100vh - 140px);
  margin: 0;
  background-color: rgb(63, 63, 63);
  overflow: hidden;  
}
.checkbox{
  width: 20px;
  height: 20px;
  margin-left: 5px;  
  box-shadow: none;
}
.closeButton{
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #ff767d;
  color: white;
  text-align: center;
  line-height: 20px;
  width: 20px;
  height: 20px;
  transition: 500ms;
  z-index: 20;
  cursor: pointer;
  border-radius: 3px;
  user-select: none;
}
.closeButton:hover{
  background-color: #ffc5c5;
  box-shadow: 0 0 10px #ff767d;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes background {
  0% {
    background-color: purple;    
  }  
  50% {
    background-color: rgb(83, 28, 83);    
  }  
  100% {
    background-color: purple;    
  }  
}
@keyframes appContainerGlow{
  0% {box-shadow: 0 0 14px rgba(255, 217, 0, 0.5);}
  50% { box-shadow: 0 0 14px gold}
  100% {box-shadow: 0 0 14px rgba(255, 217, 0, 0.5);}
}
@keyframes hover{
  0% {margin-bottom:0}
  25% {margin-bottom:0}
  50% {margin-bottom:3px}
  75% {margin-bottom:0}
  100% {margin-bottom:0}
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.appContainerOuter{
  animation: background 8500ms infinite;
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(rgba(47, 57, 197, 0.219), rgba(0, 0, 0, 0), black);
  position: fixed;
  left: 0;
  top: 0;
}
.appContainer{
  border-radius: 5px;
  border: 1px solid black;
  height: 300px;
  max-width: 600px;
  width: 90%;
  margin: 0 auto;
  margin-top: 180px;
  background-color: rgb(43, 41, 49);  
  background-image: linear-gradient(rgb(68, 65, 77), rgb(45, 42, 49));
  animation: appContainerGlow 4000ms infinite;
  transition: 500ms;
  text-align: center;
  padding-top: 60px;
  padding-left: 10px;
  box-sizing: border-box;
  position: relative;
}
.button{
  background-image: linear-gradient(gold, rgb(204, 173, 0));
  box-shadow: 0 0 10px rgb(22, 20, 32);
  border-radius: 5px;
  height: 40px;
  width: 100px;
  transition: 1000ms;
  line-height: 40px;
  font-weight: bold;
  user-select: none;  
}
.button:hover{
  box-shadow: 0 0 10px gold;
}
.buttonBig{
  height: 60px;
  width: 150px;
  line-height: 60px;
  font-size: 20px;
  display: inline-block;
  margin-top: 60px;
}
.wordArrayInputBox{
  height: 30px;
  width: 80%;
  border-radius: 3px;
  padding-left: 5px;  
  background-color: gray;
  outline: none;
  border: none;
}
.hidden{
  height: 0;
  width: 0;
  overflow: hidden;
}
.wordArrayMessageDisplay{
  position: absolute;
  top: 15px;
  width: calc(100% - 20px);  
  text-align: center;
  color: white;
  font-size: 12px;  
  user-select: none;
  margin-bottom: 15px;
}
.infoButton{
  height: 25px;
  width: 25px;
  line-height: 16px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  color: rgb(138, 141, 175);
  float: left;
  margin: 2px;
  z-index: 2;
  transition: 500ms;
}
.infoButton img{
  height: 100%;
  width: 100%;
}
.infoButton:hover{
  border-radius: 50%;
  background-color: rgba(128, 128, 128, 0.445);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.452)  ;

}
.infoButtonDisplay{    
  /* display: none; */
  height: 0;
  width: 0;
  opacity: 0;
  color: white;
  border-radius: 3px;
  position: absolute;   
  bottom: 10px;
  left: 0px;    
  overflow-y: auto;
  direction: rtl;
  transition: 500ms;
  border: 0px solid black;

}
.width300{
  width: 300px;
}
.infoButton:hover .infoButtonDisplay{
  height: 300px;
  width: 220px; 
  background-color: gray;
  border: 1px solid black;
  opacity: 1;

}
.circleButtonHolder{
  height: 20px;
  position: absolute;
  bottom: 10px;
  left: 5px;  
}
.randomStuffWindow{
  height: 600px;
  width: 1200px;
  background-color: gray;
  border-radius: 5px;
  position: fixed;
  top: 40px;
  left:calc(50vw - 600px);
  z-index: 4;
  text-align: center;
}
.bottomRight{
  position: absolute;
  right: 5px;
  bottom: 5px;
  color: white;
  cursor: pointer;
}
.inlineBlock{
  display: inline-block;
  margin: 0 10px;
}
/* .window{
  position: fixed;
  background-image: linear-gradient(rgba(151, 149, 149, 0.9), rgb(63, 63, 63));
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 0 5px 10px black;
  height: 80vh;
  top: 10vh;
  width: 80vw;
  left: 10vw;
  z-index: 10;
} */
.chartWindow{  
  overflow-y: auto;
}
.chartContainer{
    text-align: center;
    display: inline-block;
}
.currentDisplay{
  color: white;
}
.settingsButton{
  height: 30px;
  line-height: 20px;
  padding: 5px;
  box-sizing: border-box;
  transition: 500ms;
}
.settingsButton:hover{
  background-color: rgba(255, 255, 255, 0.274);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.473);;
}
.hintMenuInner{
  margin: 10px;
  padding: 10px;
  box-sizing: border-box;
}
.hintMenuLine{
  margin: 5px;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid black;
}
.settingsArea{
  padding: 10px;
  box-sizing: border-box;
  padding-top: 20px;
  height: calc(100% - 50px);
  overflow: auto;
  margin-bottom: 50px;
}
.settingsRow{
  padding: 10px;
  box-sizing: border-box;
  /* border-bottom: solid 1px black; */
  display: inline-block;
  width: 40%;
}

.settingsRow input{
  width: 40px;
  text-align: center;
}
.sources{
  width: 90%;
  padding: 10px;
  padding-top: 0;
  margin: 10px;
  border: 1px solid black;
  border-radius: 5px;
  display: inline-block;
}
.sources div{
  margin: 20px;
  display: inline-block;
}
.editButton{
  position: absolute;
  top: 5px;
  left: 5px;
  cursor: pointer;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  border-radius: 5px;  
  border: 1px solid rgb(112, 112, 112);
  user-select: none;
  box-sizing: border-box;
  font-size: 20px;
  transition: 500ms;
}
.editButton:hover{
  background-color: rgba(146, 146, 146, 0.5);
}