.imageUploaderSB{    
    width: 100%;
    min-height: 100px;
    min-width: 100px;
    position: relative;
}
.imageArrow{
    position: absolute;
    top: calc(50% - 20px);
    cursor: pointer;
    background-color: rgba(210, 208, 208, 0.212);
    color: purple;
    font-weight: bold;
    opacity: 0.8;
    padding: 10px;
    transition: 500ms;
    user-select: none;
    border: 1px solid purple;
}
.imageArrow:hover{
    opacity: 1;
    background-color: rgba(210, 208, 208, 0.622);
}
.imageArrowLeft{
    left: 10px;
}
.imageArrowRight{
    right: 10px;
}
.imageBottomInfo{
    position: absolute;
    color: white;
    bottom: 0px;
    left: 0px;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.493);
    padding: 5px 10px;
    width: 100%;    
    height: 30px;
}
.imageBottomButton{
    position: absolute;
    right: 5px;
    bottom: 5px;
    cursor: pointer;
    height: 20px;
    width: 20px;
}
.imageTile{
    height: 200px;
    width: 200px;
    display: inline-block;
    margin: 5px;
    position: relative;
    border: 1px solid black;
    border-radius: 5px;
}
.imageTile img{
    border-radius: 5px;
    object-fit: cover;
}