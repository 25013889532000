.tuitionBox{
    vertical-align: top;
    display: inline-block;
    width: 100%;
    border: 1px solid black;
    font-weight: normal;
}
.tuitionBoxTop{
    padding-bottom: 5px;
}
.tuitionBoxTitleArea{
    text-align: center;
    padding-bottom: 10px;
}
.tuitionButtonsRow div{
    cursor: pointer;
    padding: 10px 20px;
    width: 140px;
    text-align: center;
}
.tuitionButtonSelected{
    border: 1px solid black;
}   
.tuitionRatesRows .flexRow div{
    padding: 5px 10px;
}