.programDetailPage{
    width: 95%;
    display: inline-block;
    background-color: rgb(242, 242, 242);
    position: relative;
    padding: 20px 80px;
}
.programDetailSideNav{
    width: 260px;
    height: calc(100vh - 380px);
    /* min-height: 800px; */
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    background-color: white;
}
.sideNavTitle{
    padding: 5px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    background-color: rgb(231, 174, 3);
    font-weight: bold;
    font-size: 20px;
}
.programDetailSideNavItem{
    padding: 10px;
    transition: 500ms;
    cursor: pointer;
    user-select: none;
}
.programDetailSideNavItem:hover{
    background-color: rgb(237, 237, 237);
}
.sideNavTop{
    position: fixed;
    top: 80px;
    left: 10%;
    height: calc(100vh - 380px);
}

.mainImage{
    height: 300px;
    width: 220px;
    background-color: rgb(254, 218, 162);
    float: right;
    margin: 20px;
}
.detailSections{

}
.detailSection{
    padding-bottom: 40px;
    border-bottom: 2px solid #fc0;

}
.detailSection:last-child{
    padding-bottom: 40px;
    border-bottom: 2px solid rgba(218, 165, 32, 0);

}
.detailSectionType{
    width: 100%;
    text-align: left;
    padding: 10px;
    padding-left: 20px;
    font-weight: bold;
}
.overviewTitle{
    font-size: 40px;
    font-weight: bold;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 40px;
}
.overviewText{
    text-align: justify;
}


@media screen and (min-width: 1250px) {
    .programDetailPage{
        width: 80%;
    }
   
}


/* At A Glance */

.atAGlanceSection{
    background-color: #f7f7f7;
    font-weight: bold;
    padding: 40px 20px;
    text-align: center;
    text-align: left;
}
.atAGlanceLeft{
    display: inline-block;
    text-align: left;
}
.atAGlanceTitle{
    font-size: 30px;
    padding-bottom: 20px;
}
.glanceItem{
    padding: 10px 5px;
}
.glanceItemGrey{
    color: grey;
    font-size: 18px;
}
.glanceItemText{
    font-size: 22px;

}
.calendarBox{
    display: inline-block;
    width: 80px;
    margin: 0px 100px;
    vertical-align: top;
    margin-top: 50px;
}
.calendarBoxNumber{
    font-size: 50px;
    margin: -5px 0;
}
.calendarAndYears{
    display: inline-block;   
}

/* Description */

.descriptionSection{
    padding: 60px 0px;
    text-align: center;
}
.descriptionLeft{
    display: inline-block;
    width: calc(100% - 400px);
    padding: 0 20px;
}
.descriptionLeftTitle{
    font-size: 30px;
    font-weight: 100;
    padding-bottom: 20px;
}
.descriptionRight{
    width: 400px;
    display: inline-block;
    vertical-align: top;
}
.descriptionRightImg{
    height: 400px;
    width: 400px;
    vertical-align: top;
}
.descriptionRightTitle{
    font-size: 40px;
    font-weight: bold;
    border-bottom: 2px solid #fc0;
    margin-bottom: 40px;
}

.hilightRowImg{
    width: 100px;
}
.highlightRowText{
    display: inline-block;
    width: calc(100% - 100px);
    vertical-align: top;
    padding: 15px;
}

/* Application Deadlines */

.applicationDeadlinesSection{
    width: 100%;
    position: relative;
    background-color: #fc0;
    padding-top: 30px;
    padding-bottom: 40px;
}
.applicationDeatlineTitle{
    font-size: 35px;
    font-weight: bold;
}
.applicationDeadlineLeftDates{
    padding: 20px 60px;
    display: flex;
    justify-content: space-between;
}
.applicationDeadlineLeftDate{
    display: inline-block;
}
.applicationDeadlineLeftDateTitle{
    font-size: 20px;
    font-weight: normal;
}
.applicationDeadlineLeftDateDate{
    font-size: 20px;
    font-weight: bold;
}
.applicationDeatlinesSectionRight{
    float: right;
    width: 28%;
    position: relative;
}
.applicationDeadlineRightText{
    font-size: 30px;
    color: white;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    text-align: left;
    font-weight: bold;
}
.applicationButton2{
    width: 200px;
    background-color: #fc0;
    font-weight: bold;
}
.applicationButton2:hover{
    background-color: rgb(254, 237, 170);
    box-shadow: 0 0 10px #fc0;
}

/* Applicatoin Sectoin 2 */
.applicationSection2{
    padding: 30px 0;
    background-image: url("../../Images/greyBackground.webp");
    background-size: cover;
    box-sizing: content-box;
    padding-right: 20px;
}
.applicationSection2 img{
    height: 400px;
    width: 400px;
    display: inline-block;
    vertical-align: top;
}
.applicationSection2Text{
    width: 530px;
    /* width: (100% - 400px); */
    display: inline-block;
    vertical-align: top;
    text-align: left;
    color: white;
    margin-left: 40px;
    margin-top: 80px;
    text-align: center;
}
.applicationSection2Title{
    font-size: 31px;
    font-weight: bold;
    border-bottom: 4px solid #fc0;
}
.applicationSection2Title2{
    font-size: 25px;
    font-weight: 500;
    padding-top: 40px;
}
.applicationSection2Title3{
    font-size: 16px;
    font-weight: 500;
    padding-top: 10px;
}
.applicationSection2ButtonConteiner{
    padding-top: 40px;
}

/* Multiple Use */

.programPageWidth{
    width: 1000px;
    display: inline-block;
}
.pageWidthAbsolute{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: green;
    margin-left: -10px;
}

.tuitionBoxContainer{
    width: 300px;
    display: inline-block;
    vertical-align: top;
}

@media screen and (max-width: 1200px){
    .programPageWidth{
        width: 900px;

    }
    .calendarBox{
        margin: 50px 30px;
        margin-right: 40px;
    }
    /* .applicationSection2Text{
        margin-top: 20px;
        width: 750px;
        padding-bottom: 40px;
    } */
    .applicationSection2Text{
        /* padding-left: 10px; */
        margin-left: 10px;
        width: 530px;
    }

}

@media screen and (max-width: 1000px){
    .programPageWidth{
        width: 100%;
    }
    .calendarBox{
        margin: 50px 100px;
        margin-right: 0;
    }
    .calendarAndYears{
        width: 100%;
        padding-top: 20px;
    }
    .descriptionRightImg{
        width: 100%;
    }
    .descriptionRight{
        width: 100%;
        margin-top: 20px;
    }
    .descriptionLeft{
        width: 100%;

    }
    .descriptionRight{
        text-align: center;
        width: 100%;
        display: inline-block;
    }
    .descriptionRightInner{
        width: 100%;
        display: inline-block;
    }
    .descriptionRightText{
        width: 80%;
        display: inline-block;
    }
    .descriptionLeft{
        width: 90%;
        margin-left: 5%;
    }
    .applicationDeatlinesSectionLeft img{
        width: 1000px;
        margin-left: -600px;
    }
    .applicationSection2Text{
        width: 600px;
        margin-top: 20px;
        padding-bottom: 40px;
    }
    .applicationSection2Title{
        font-size: 35px;
    }

}
@media screen and (max-width: 650px){
    .atAGlanceTitle{
        font-size: 25px;
        padding-bottom: 5px;
    }
    .glanceItem{
        padding: 5px 0;
    }
    .glanceItemGrey{
        font-size: 14px;
    }
    .calendarBox{
        margin-left: 10px;
    }
    .descriptionLeft{
        width: 98%;
        margin-left: 1%;
    }

    .applicationSection2Text{
        width: 95%;
        margin-top: 20px;
        padding-bottom: 40px;
    }
    .applicationSection2Title{
        font-size: 25px;
    }
    .applicationSection2PageWidth img{
        width: 100%;
    }
    .applicationDeadlineLeftDate{
        display: block;
        padding-top: 20px;
    }
}


/* Global */
.textAlignLeft{
    text-align: left;
}