.eventTicketContainer{
    min-height: 300px;
    width: 100%;
}
.eventTicket{
    height: 200px;
    width: 600px;
    border: 1px solid black;
    padding: 10px;
    position: relative;
}
.eventTicketBottomBox{
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
    background-color: black;
    font-weight: bold;
    padding: 10px 20px;
}
.eventTicketLeft{
    width: calc(100% - 200px);
}
.eventTicketEventName{
    font-size: 20px;
    font-weight: bold;
}
.eventTicketCodeBox{
    height: 200px;
    width: 200px;
    border-left: 1px solid black;
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
}
.ticketMetaData{
    width: 31%;
    display: inline-block;
    padding: 10px 0;
    vertical-align: top;
}
.ticketMetaDataTitle{
    font-size: 16px;
    font-weight: bold;
    padding: 5px 0;
}
.ticketDisclaimer{
    width: 100%;
    padding-top: 20px;
}
.ticketDisclaimerTitle{
    font-size: 16px;
    font-weight: bold;
}
.ticketDisclaimerText{
    font-size: 10px;
    max-width: 600px;
    padding-bottom: 10px;
}
.ticketFormBox{
    min-width: 640px;
}