.homepage{
    position: relative;
    background-color: rgb(243, 243, 243);
}
.banner{
    width: 100%;
    height: 95vh;
    position: relative;
}
.bannerText{
    color: white;
    font-size: 20px;
    position: absolute;
    bottom: 120px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 2;
    font-size: 40px;
    font-weight: bold;
}
.banner img{
    object-fit: cover;
}
.bannerCover{
    width: 100%;
    height: 95vh;
    background-color: rgba(0, 0, 0, 0.362);
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.131), black);
    position: absolute;
    top: 0;
    left: 0;
    /* z-index: 2; */
}
.bannerTilesArea{
    width: 90%;
    margin-left: 5%;
    position: relative;
    box-sizing: border-box;
    margin-top: -80px;
}
.bannerTileRow{
    display: inline-block;
    width: 50%;
}
.bannerTile{
    width: calc(50% - 40px);
    height: 190px;
    overflow: hidden;
    vertical-align: top;
    margin:  20px;
    margin-top: 0;
    background-color: white;
    border-radius: 3px;
    display: inline-block;
    /* border: 2px solid black; */
    box-shadow: 0 5px 10px rgba(81, 81, 81, 0.5);
    overflow: hidden;
}
.bannerTileType{
    /* line-height: 30px; */
    padding: 10px;
    font-weight: bold;
    border-bottom: 1px solid black;
}
.bannerTileTypeText{
    display: inline-block;
    vertical-align: top;
}
.bannerTileTypeText:hover{
    text-decoration: underline;
}
.bannerTileType img{
    height: 20px;
    width: 22px;
    margin-right: 10px;
    display: inline-block;
}

.bannerTileTitle{
    height: 50px;
    border-bottom: 1px solid black;
    color: black;
    font-size: 20px;
    line-height: 45px;
    background-color: rgba(255, 255, 255, 0);
    transition: 1000ms;
}
.bannerTileButtons{
    padding: 10px 0;
}
.bannerTileButton{
    height: 40px;
    line-height: 35px;
    font-weight: 500;
    width: 100%;
    text-align: left;
    padding: 0 30px;
    box-sizing: border-box;
    cursor: pointer;
    transition: 500ms;
    text-align: center;
    border-top: 2px solid rgba(255, 255, 255, 0);
    border-bottom: 2px solid rgba(255, 255, 255, 0);
}
.bannerTileButton:hover{
    /* background-color: rgba(212, 212, 212, 0.25); */
    /* background-color: rgb(255, 253, 241); */
    /* border-top: 2px solid goldenrod; */
    /* border-bottom: 2px solid goldenrod; */
    text-decoration: underline;
    /* font-size: 16.5px; */
}
.openDaysSection{
    height: 450px;
    width: 100%;
    position: relative;
}
.openDaysSectionLeft{
    background-image: linear-gradient(to right, rgb(0, 0, 0) 85%, rgba(0, 0, 0, 0.6));
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 55%;
    z-index: 2;
    color: white;
}
.openDaysSectionRight{
    float: right;
    width: 60%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
}
.openDaysSectionRight img{
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
}
.openDatTextArea{
    position: absolute;
    top: 50px;
    left: 100px;
    width: 400px;
    text-align: left;
}
.openDayTitle{
    font-size: 50px;
    font-weight: bold;
}
.openDayText{
    margin-top: 20px;
    font-size: 20px;
}
.openDayButton{
    margin: 20px 0;
    width: 250px;
    height: 40px;
}
.openDayButton:hover{
    background-color: rgb(255, 244, 183);
    box-shadow: 0 0 20px gold;
}
.openDayTopTitle{
    min-height: 100px;
    font-size: 40px;
    font-weight: bold;
    background-color: black;
    color: white;
    padding: 20px 10px 10px 10px;
    box-sizing: border-box;

}
.openDaysDescription{
    min-height: 200px;
    background-color: black;
    padding: 20px 140px;
    text-align: left;
    color: white;
}
.homeSectionPad{
    padding: 40px 0;
}
.datesTitle{
    font-size: 40px;
    font-weight: bold;
    padding-bottom: 20px;
    padding-left: 10px;
    position: relative;
    margin-bottom: 10px;
}
.dateTile{
    background-color: white;
    box-shadow: 0 5px 10px rgba(81, 81, 81, 0.5);
    display: inline-block;
    width: calc(33% - 20px);
    margin: 10px;
    vertical-align: top;
    min-height: 520px;
    position: relative;
}
.readMoreButton{
    width: 80%;
    position: absolute;
    bottom: 10px;
    left: 10%;
}
.dateImage{
    width: 100%;
    height: 300px;
    vertical-align: top;
}
.dateImage img{
    object-fit: cover;
}
.dateTitle{
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    padding-top: 10px;
    box-sizing: border-box;
}
.dateText{
    padding: 10px 30px 20px 30px;
    box-sizing: border-box;
}


.facultyTile{
    width: calc(50% - 20px);
    margin: 10px;
    background-color: grey;
    min-height: 260px;
    vertical-align: top;
    background-color: white;
    display: inline-block;
    box-shadow: 0 5px 10px rgba(81, 81, 81, 0.5);
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
}
.facultyTileImage{
    width: 40%;
    object-fit: cover;
    border-radius: 5px;
}
.facultyTileTitle{
    font-size: 25px;
    font-weight: bold;
}
.facultyTileText{
    display: inline-block;
    vertical-align: top;
    padding-left: 20px;
    box-sizing: border-box;
    width: 60%;
}
.facultyDescriptionTile{
    background-color: rgba(253, 253, 253, 0);
    box-shadow: 0 5px 10px rgba(81, 81, 81, 0.0);
    padding: 0px;
}
.facultyDescriptionTitle{
    font-size: 35px;
    font-weight: bold;
}
.facultyDescriptionText{
    padding-top: 20px;
    font-size: 18px;
    max-width: 550px;
    text-align: left;
}
.facultyTileDescriptionText{
    padding-top: 20px;
    font-size: 18px;
    max-width: 550px;
    text-align: left;
}


.under1200{
    display: none;
}
.over1200{
    display: block;
}
@media screen and (max-width: 1250px) {
    .bannerTileRow{
        width: 100%;
    }
    .dateTile{
        width: calc(100% - 20px);
        padding-bottom: 40px;
    }
    .dateImage{
        height: 500px;
    }
    .over1200{
        display: none;

    }
    .under1200{
        display: block;
    }
    .openDaysSection{
        height: auto;
    }

    .openDaysDescription{
        text-align: center;
        min-height: auto;
        padding-bottom: 30px;
    }
    .openDayText{
        max-width: 600px;
        display: inline-block;
    }

}
@media screen and (max-width: 1200px) {
    .facultyTile{
        width: 100%;
    }
    .facultyDescriptionTitle{
        text-align: center;
    }
    .facultyDescriptionText{
        max-width: 100%;
        text-align: center;
    }
    .facultyDescriptionTile{
        min-height: auto;
        padding-bottom: 20px;
    }
}
@media screen and (max-width: 800px) {
    .bannerTilesArea{
        width: 100%;
        margin-left: 0;
    }
    .openDaysDescription{
        padding: 20px 40px;
    }
    .openDayTopTitle{
        font-size: 30px;
    }

}
@media screen and (max-width: 650px) {
    .bannerTile{
        width: calc(100% - 40px);
        margin-bottom: 20px;
    }
}