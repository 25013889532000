.wordDisplay{
    padding: 5px 10px;
    border-radius: 10px;
    transition: 500ms;
    position: relative;

    cursor: pointer;  
    display: inline-block;
    padding: 5px 10px;
    margin: 5px;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 30px;
    color: white;
    font-weight: bold;
    transition: 500ms;
    display: inline-block;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.0);
}

.wordDisplay:hover{
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.wordDisplayOpen{
    background-color: rgba(146, 146, 146, 0.5);
    font-size: 20px;
    background-color: grey;
    position: relative;
    display: inline-block;
    padding: 5px 10px;
    margin: 5px;
    box-sizing: border-box;
    border-radius: 5px;
    color: white;
    font-weight: bold;  
}