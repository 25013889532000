.App {
  text-align: center;
  overflow: hidden;
}

/* Base Components */

div{
  box-sizing: border-box;
}
input{
  outline: none;
  border: none;
  box-sizing: border-box;
  width: 80%;
  border-radius: 20px;
  line-height: 30px;
  background-color: rgb(255, 255, 255);
  padding: 5px 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.395) inset;
  margin-bottom: 20px;
}
textarea{
  outline: none;
  border: none;
  box-sizing: border-box;
  width: 80%;
  border-radius: 5px;  
  background-color: rgb(255, 255, 255);
  padding: 5px 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.395) inset;
  margin-bottom: 20px;
}
select{
  outline: none;
  border: none;
  box-sizing: border-box;
  width: 80%;
  border-radius: 20px;
  line-height: 30px;
  background-color: rgb(255, 255, 255);
  padding: 10px 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.395) inset;
}
button{
  background-color: #fc0;
  border-radius: 20px;
  padding: 10px 40px;
  transition: 1000ms;
  cursor: pointer;
  user-select: none;
  outline: none;
  border: none;
  font-weight: bold;
}
button:hover{
  background-color: #fc0;
  background-color: #ffca2a;
  background-color: rgb(255, 224, 48);
}
a{
  text-decoration: none;
  color: black;
  cursor: pointer;
  user-select: none;
}
img{
  height: 100%;
  width: 100%;
  object-fit: contain;
  user-select: none;
}
pre{
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  max-width: 100%;
  box-sizing: border-box;
  word-wrap: normal;
  white-space: pre-wrap;
  margin: 0;
  text-align: left;
}

/* Page Sizing */

.pageInnerWidth{
  width: 80%;
  min-height: 100px;
  display: inline-block;
  max-width: 1000px;
}
.pageInnerWidth2{
  width: 90%;
  min-height: 100px;
  display: inline-block;
  max-width: 1200px;
}
.pageMarginTop{
  padding-top: 0px;
}
.pageContainer{
  padding-top: 80px;
}
.innerPage{
  min-height: calc(100vh - 380px);
}

.smallTile{
  min-width: 200px;
  height: 190px;
  overflow: hidden;
  vertical-align: top;
  margin:  20px;
  margin-top: 0;
  background-color: white;
  border-radius: 3px;
  display: inline-block;
  /* border: 2px solid black; */
  box-shadow: 0 5px 10px rgba(81, 81, 81, 0.5);
  overflow: hidden;
}

.page{
  min-height: calc(100vh - 80px);  
  min-height: calc(100vh - 300px);
  text-align: center;
  padding-bottom: 40px;
}
.padTop10{
  padding-top: 10px;
}
.padTop20{
  padding-top: 20px;
}
.flexCenter{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box{
  width: 200px;
  box-shadow: 0 5px 10px rgba(186, 186, 186, 0.535);
  margin: 10px 5px;
  padding: 30px 15px;
  box-sizing: border-box;
  display: inline-block;
  text-align: left;
  vertical-align: top;
}
.inlineBlock{
  display: inline-block;
}
.inlineLink{
  color: rgb(45, 10, 184);
  text-decoration: underline;
}
.bottomButtons{
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
}
.errorMessage{
  width: 100%;
  text-align: center;
  padding: 10px;
  color: red;
}
.center{
  width: 100%;
  text-align: center;
}
/* Titles */

.pageTitle{
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.pageTitle2{
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 5px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
}
.pageTitle3{
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 5px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
}
.pageTitleUnderline{
  border-bottom: 2px solid #fc0;
}

/* Style Components */

.left{
  text-align: left;
  padding-left: 0px;
}
.goldBackground{
  background-color: #fc0;
  padding: 10px;
  color: black;
}
.titleFont{
  font-size: 20px;
  font-weight: bold;
  height: 40px;
}
.boldFont{
  font-weight: bold;
}
.boldUnderline{
  font-weight: bold;
  border-bottom: 1px solid black;
  padding-bottom: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  margin-top: 10px;
  font-size: 20px;
}
.noTopPad{
  padding-top: 0;
}
.topBorder{
  border-top: 1px solid black;
}
.width60{
  width: 60%;
  display: inline-block;
  vertical-align: top;
  padding-right: 20px;
  padding: 0;
  padding-right: 40px;
}
.width40{
  width: 40%;
  display: inline-block;
  vertical-align: top;
}
.flexRow{
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}
.flexRow div{
  display: inline-block;
  padding: 0 20px;
}
.fullHW{
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 1000px){
  .pageInnerWidth{
      width: 95%;
      /* margin-left: 2.5%; */
  }
}
@media screen and (max-width: 850px){
  .pageTitle{
    font-size: 20px;
  }
  .pageTitle2{
    font-size: 18px;
  }
  .pageTitle3{
    font-size: 16px;
  }
  .pageInnerWidth2{
    width: 98%;
  }
}
@media screen and (max-width: 750px){
  .width60{
    width: 100%;
    padding-right: 0;
  }
  .width40{
    width: 100%;
    margin-top: 20px;
  }
}