.eventTile{
    background-color: white;
    box-shadow: 0 5px 10px rgba(81, 81, 81, 0.5);
    display: inline-block;
    width: calc(33% - 20px);
    margin: 10px;
    vertical-align: top;
    min-height: 500px;
    position: relative;
}
.eventTileImage{
    width: 100%;
    height: 300px;
    vertical-align: top;
}
.eventTileImage img{
    object-fit: cover;
}
.eventTileDescription{
    padding: 10px 20px 20px 20px;
    box-sizing: border-box;
}
.sometimesHiddenTile{
    display: none;
}


@media screen and (max-width: 1200px) {
    .eventTile{
        width: calc(50% - 20px);
        padding-bottom: 40px;
    }
    .sometimesHiddenTile{
        display: inline-block;
    }
}
@media screen and (max-width: 700px) {
    .eventTile{
        width: calc(80% - 20px);
        /* margin-left: calc(10%); */
        padding-bottom: 40px;
    }
    .sometimesHiddenTile{
        display: none;
    }
}
@media screen and (max-width: 550px) {
    .eventTile{
        width: calc(100% - 20px);
        /* margin-left: calc(10%); */
        padding-bottom: 40px;
    }
}