.dbInput{
    width: calc(50% - 10px);
    margin: 5px;
    display: inline-block;
    text-align: left;
    margin-top: 10px;
    position: relative;
    overflow: hidden;
    vertical-align: top;
    border-top: 1px solid black;
    padding-top: 5px;
}
.noBorderTop{
    border-top: none;
}
.dbInputFullWidth{
    width: calc(100% - 10px);
}
.dbInputLabel{
    padding-bottom: 5px;
    font-size: 12px;
}
.dbInputInput{
    width: 100%;
    margin: 0;
    border-radius: 0;
}
.dbInputReview{
    width: calc(100% - 35px);
    margin: 0;
    overflow: hidden;
    min-height: 22px;
    max-height: 400px;
    overflow-y: auto;
}
.dbInputTextArea{
    min-width: calc(100% - 35px);
    max-width: calc(100% - 35px);
    margin: 0;
    min-height: 120px;
}
.dbInputReviewEdit{
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    height: 20px;
    width: 20px;
}