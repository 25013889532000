.courseOverviewSection{
    padding-bottom: 60px;
    background-color: white;
}
.courseOverviewTitle{
    font-weight: bold;
    font-size: 30px;
}
.courseOverviewDisclaimer{
    padding-top: 30px;
    font-size: 12px;
    text-align: left;
}
.courseOverviewYearSection{
    padding: 20px;
    border-bottom: 2px solid rgba(218, 165, 32, 0.4);
    text-align: left;
    max-height: 60px;
    overflow: hidden;
    transition: 1000ms;
    cursor: pointer;
    position: relative;
}
.courseOverviewYearSectionOpen{
    max-height: 600px;
}
.courseOverviewYearSectionMinMaxButton{
    position: absolute;
    top: 20px;
    right: 10px;
    height: 20px;
    width: 20px;
    line-height: 14px;
    text-align: center;
    font-size: 25px;
    color: grey;
    user-select: none;
}
.courseOverviewYearSectionTitle{
    font-size: 20px;
    font-weight: bold;
    user-select: none;
}
.yearCourses{
    padding-top: 30px;
}
.courseRow{
    width: 45%;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    padding-bottom: 20px;
    user-select: none;
    /* border-left: 1px solid rgba(0, 0, 0, 0.3); */
    padding-left: 5%;
    cursor: pointer;
}
.courseRowLeft{
    padding-right: 5%;
    padding-left: 0;
    border-left: none;
    /* border-right: 1px solid rgba(0, 0, 0, 0.3); */
}
.courseRowTitle{
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}
.courseRowTitleDescription{
    max-height: 0px;
    transition: 1000ms;
    overflow: hidden;
    border-left: 1px solid rgba(128, 128, 128, 0.5);
    margin-left: 6px;
}
.courseRowTitleDescriptionOpen{
    max-height: 200px;
    padding: 10px 0;
    padding-left: 10px;
    margin-left: 3px;

}

@media screen and (max-width: 800px){
    .courseRow{
        width: 100%;
        margin: 0;
        padding-left: 0;
    }
    .courseRowLeft{
        border-right: none;
        margin-right: 0;
        padding-right: 0;
    }
}