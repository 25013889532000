.online-degree {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .header {
    grid-column: 1 / -1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .header h1 {
    margin: 0;
    font-size: 1.5em;
  }
  
  .header h2 {
    margin: 0;
    font-size: 2em;
  }
  
  .apply-now-btn {
    background-color: #0056b3; /* Replace with the actual color */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .sidebar nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar nav ul li {
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .start-date {
    background-color: #f0f0f0; /* Replace with the actual color */
    padding: 20px;
    margin-top: 20px;
  }
  
  .start-date p {
    margin: 0;
    font-weight: bold;
  }
  
  .apply-today-btn {
    background-color: #007bff; /* Replace with the actual color */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    display: block;
    margin-top: 10px;
  }
  
  .main-content h3 {
    margin: 0;
    color: #333; /* Replace with the actual color */
  }
  
  .main-content h4 {
    margin-top: 5px;
    margin-bottom: 20px;
    color: #0056b3; /* Replace with the actual color */
  }
  
  .main-content ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .main-content ul li {
    margin-bottom: 5px;
    /* background-image: url('/path-to-your-bullet-image.png'); */
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 25px; /* Adjust based on your bullet image size */
  }