.cRow{
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 5px;
    border-bottom: 2px solid rgba(218, 165, 32, 0.4);
    text-align: left;
    /* max-height: 60px; */
    overflow: hidden;
    transition: 1000ms;
    position: relative;
}
.cRowInner{
    max-height: 0px;
    overflow: hidden;
    transition: 1000ms;
}
.cRowInnerOpen{
    max-height: 1200px;
    padding-top: 10px;
    padding-bottom: 20px;
    overflow-y: auto;
}
.minMaxButton{
    position: absolute;
    top: 20px;
    right: 10px;
    height: 20px;
    width: 20px;
    line-height: 14px;
    text-align: center;
    font-size: 25px;
    color: grey;
    user-select: none;
}
.cRowTitle{
    font-size: 20px;
    font-weight: bold;
    user-select: none;
    cursor: pointer;
    padding-bottom: 10px;
    padding-top: 10px;
}

@media screen and (max-width: 850px){
    .cRowTitle{
        font-size: 16px;
    }
}