.videoPlayerContainer{
    position: relative;
    display: inline-block;
    
}
.videoPlayerContainerMobile{
    
}
.videoPlayer{
    width: 100%;
}
.videoPlayerTopLeft{
    height: 43px;
    padding-right: 15px;
    position: absolute;
    top: 8px;
    left: 8px;
    background-color: rgba(71, 71, 71, 0.534);
    border-radius: 20px;
    color: white;
    cursor: pointer;
    z-index: 2;
}
.videoPlayerTopLeft:hover{
    background-color: rgba(119, 119, 119, 0.534);

}
.videoPlayerTopLeft img{
    height: 35px;
    width: 35px;
    background-color: black;
    border-radius: 50%;
    margin: 4px;
    position: absolute;
    left: 0px;
    top: 0;
}
.videoPlayerTopLeftText{
    display: inline-block;
    vertical-align: top;
    padding-top: 6px;
    margin-left: 5px;
    text-align: left;
    margin-left: 43px;
}
.videoPlayerTopLeftChannelName{
    font-size: 13px;
}
.videoPlayerTopLeftSubs{
    font-size: 10px;
}
.videoPlayerTopLeftClosed{
    width: 43px;
    overflow: hidden;
    transition: 100ms;
}
.videoPlayerTopLeftClosed:hover {
    width: 180px;
}


.videpPlayerPlayButton{
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 20px);
    height: 40px;
    width: 60px;
    z-index: 2;
    cursor: pointer;
}
.bottomLeftSquare{
    position: absolute;
    left: 2px;
    bottom: 10px;
    width: 120px;
    padding: 10px 20px;
    background-color: rgba(39, 39, 39, 0.709);
    z-index: 2;
    cursor: pointer;
}
.bottomLeftSquare img{
    width: 100%;
    object-fit: contain;
}