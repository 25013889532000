.applyTopBar{
    background-color: #fc0;
    line-height: 50px;
    font-size: 20px;
    font-weight: bold;
    padding: 5px;
    box-sizing: border-box;
}
.applicatoinTitle{
    font-size: 30px;
}
.applicationPageBackground{
    background-color: rgb(242, 242, 242);
}
.applicationArea{
    background-color: white;
    padding: 20px;
    margin: 20px 0;
    width: 80%;
    max-width: 800px;
    display: inline-block;
}
.inputLine input{
    border-radius: 0px;
    width: 100%;
    margin-bottom: 0;
}
.applicationArea select{
    border-radius: 0px;
    width: 100%;
}
.inputLine{
    width: calc(50% - 40px);
    margin: 5px 20px;
    display: inline-block;
    text-align: left;
}
.inputLineWhole{
    width: calc(100% - 40px);
    display: inline-block;
    text-align: center;
}
.inputLabel{
    padding: 10px 0;
    font-size: 12px;
}
.applicationMessages{
    padding-top: 10px;
}
.applicationDisclaimer{
    font-size: 12px;
    padding: 5px 0;
    width: 90%;
    display: inline-block;
}
.applyButtons{
    width: 95%;
    margin-left: 2.5%;
    display: flex;
    justify-content: space-between;
}
.applyButton{
    width: 200px;
    display: inline-block;
    margin-top: 20px;
    margin: 10px;
}
.applyButton button{
    width: 100%;
}
.applyButtonLast button{
    padding: 8px 38px;
    border: 2px solid gold;
    background-color: white;
}
.applyMessage{
    font-size: 12px;
    padding: 10px;
    padding-top: 0;
    color: rgb(39, 0, 0);
    width: 70%;
    display: inline-block;
}
.reviewField{
    outline: none;
    border: none;
    box-sizing: border-box;
    line-height: 30px;
    background-color: rgb(255, 255, 255);
    padding: 5px 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.395) inset;
    border-radius: 0px;
    width: 100%;
    margin-bottom: 0;
}
.reviewDiv{
    outline: none;
    border: none;
    box-sizing: border-box;
    width: 80%;
    border-radius: 20px;
    line-height: 30px;
    background-color: rgb(255, 255, 255);
    padding: 5px 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.395) inset;
    margin-bottom: 20px;

    border-radius: 0px;
    width: 100%;
    margin-bottom: 0;
    height: 30px;
    overflow: hidden;
    padding-top: 0;
}

.resumeApplicationContainer{
    height: calc(100vh - 600px);
    padding-top: 60px;
}
.resumeApplicationBox{
    width: 600px;
    text-align: center;
}
.requmeApplicationTitle{
    padding: 10px;
    padding-bottom: 20px;
    font-size: 25px;
    font-weight: bold;
}
.requmeApplicationDescription{
    padding: 10px;
    padding-bottom: 20px;
    font-size: 14px;
}
.applicationSubmittedMessage{
    font-size: 30px;
    padding: 20px;
}

@media screen and (max-width: 850px) {
    .applicationArea{
        width: 100%;
    }

}
@media screen and (max-width: 650px) {
    .inputLine {
        margin-left: 0;
        margin-right: 0;
        width: calc(100% - 0px);
    }
}