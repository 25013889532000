/* Banner */

.programBanner{
    background-position: center;
    background-size: cover;
    position: relative;
    min-height: 400px;
}
.programBannerImg{
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    z-index: -1;
    object-position: center;
    background-color: rgb(199, 199, 199);
}
.programBannerText{
    width: 60%;
    max-width: 600px;
    padding-left: 20px;
    padding-top: 40px;
    color: white;
    text-align: left;
    font-weight: bold;
    padding-bottom: 20px;
    border-radius: 5px;
}
.programBannerSubtitle{
    font-size: 16px;
    margin-bottom: 30px;
    display: inline-block;
    border-radius: 5px;
}
.programBannerTitle{
    font-size: 30px;
    display: block;
    border-radius: 5px;
}
.programBannerButton{
    background-color: white;
    border-radius: 20px;
    padding: 10px 40px;
    margin-top: 40px;
    transition: 1000ms;
    cursor: pointer;
    font-weight: bold;
}

/* Search Bar */

.searchBar{
    width: 100%;
    padding: 10px 40px 0px 40px;
    box-sizing: border-box;
    margin-top: 10px;
}
.searchBarInput{
    width: 100%;
    border-radius: 20px;
    line-height: 30px;
    background-color: rgb(234, 234, 234);
    padding: 5px 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.395) inset;
}

/* Program Tiles */

.programTiles{
    padding: 30px;
    text-align: left;
}
.programTile{
    width: calc(33% - 10px);
    box-shadow: 0 5px 10px rgba(186, 186, 186, 0.535);
    margin: 10px 5px;
    padding: 30px 15px;
    box-sizing: border-box;
    display: inline-block;
    text-align: left;
    vertical-align: top;
}
.programType{
    /* line-height: 30px; */
    padding-bottom: 20px;
    font-weight: bold;
}
.programTypeText{
    display: inline-block;
    vertical-align: top;
}
.programType img{
    height: 20px;
    width: 22px;
    margin-right: 20px;
    display: inline-block;
}
.programName{
    font-weight: bold;
    font-size: 20px;
    height: 80px;
    overflow: hidden;
}
.programDescription{
    min-height: 63px;
    overflow: hidden;
}
.programTilebutton{
    margin-top: 20px;
}
.programTilebutton:hover{
    box-shadow: 0 0 10px #ffca2a;
    background-color: gold;
    color: black;
}

/* Stats */

.statsArea{
    width: 100%;
    padding: 20px 80px 40px 80px;
    box-sizing: border-box;
    background-color: rgba(211, 211, 220, 0.562);
}
.statsTitleArea{
    display: inline-block;
    width: 25%;
    vertical-align: top;
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
}
.statsTitleDisclaimer{
    font-style: italic;
    font-size: 14px;
    color: rgb(78, 78, 78);
    padding-top: 20px;
}
.statsTitle{
    font-size: 30px;
    font-weight: bold;
    
}
.statsBox{
    width: 25%;
    display: inline-block;
    vertical-align: top;
    padding: 5px;
    box-sizing: border-box;
}
.statsBox img{
    width: 60%;
}

/* Media Queries */

@media screen and (max-width: 1350px) {
    .statsTitleArea{
        width: 100%;
        text-align: center;
        padding-bottom: 40px;
    }
    .statsBox{
        width: 33%;
    }
}
@media screen and (max-width: 1100px) {
    .programTile{
        width: calc(50% - 10px);
        min-height: 282px;
    }
    .statsArea{
        padding: 20px;
    }

}
@media screen and (max-width: 750px) {
    .programTile{
        width: calc(100% - 10px);
        min-height: auto;

    }
    .statsBox{
        font-size: 12px;
    }
    .programBannerTitle{
        font-size: 20px;
    }
}
@media screen and (min-width: 1250px) {
    .programBannerText{
        margin-left: 10%;

    }
}