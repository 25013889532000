.tilesContainer{
    width: 100%;
    text-align: center;
}
.tile{
    background-color: white;
    box-shadow: 0 5px 10px rgba(81, 81, 81, 0.5);
    display: inline-block;
    width: calc(33% - 20px);
    margin: 10px;
    vertical-align: top;
    min-height: 500px;
    position: relative;
}
.tileImage{
    width: 100%;
    height: 300px;
    vertical-align: top;
    position: relative;
}
.tileImage img{
    object-fit: cover;
    width: 100%;
}
.tileTitle2{
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    padding-top: 10px;
    box-sizing: border-box;
    padding: 5px 10px;
}
.tileTitle{
    color: white;
    background-color: rgba(0, 0, 0, 0.378);
    padding: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    font-weight: bold;
    text-align: center;
}
.tileDescription{
    padding: 10px 20px 20px 20px;
    box-sizing: border-box;
    font-size: 13px;
}
.tileDescription pre{
    text-align: justify;
}


.wideTile{
    width: calc(50% - 20px);
    margin: 10px;
    background-color: grey;
    min-height: 260px;
    vertical-align: top;
    background-color: white;
    display: inline-block;
    box-shadow: 0 5px 10px rgba(81, 81, 81, 0.5);
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
}
.wideTileImage{
    width: 40%;
    height: 220px;
    object-fit: cover;
    border-radius: 5px;
    box-sizing: border-box;
}
.wideTileImageMobile{
    width: 100%;
    height: auto;
}
.wideTileTitle{
    font-weight: bold;
    padding: 10px;
    padding-bottom: 20px;
    font-size: 16px;
}
.wideTileText{
    display: inline-block;
    vertical-align: top;
    padding-left: 20px;
    box-sizing: border-box;
    width: 60%;
    position: relative;
    padding-bottom: 50px;
}
.wideTileTextMobile{
    width: 100%;
}
.wideTileText pre{
    /* text-align: justify; */
}
.wideTileReadMoreButton{
    width: calc(60% - 20px);
    position: absolute;
    bottom: 20px;
    right: 10px;
}
.wideTileReadMoreButtonMobile{
    width: calc(100% - 20px);
    position: absolute;
    bottom: 20px;
    right: 10px;
}


.sometimesHiddenTile{
    display: none;
}


@media screen and (max-width: 1200px) {
    .tile{
        width: calc(50% - 20px);
        padding-bottom: 40px;
    }
    .sometimesHiddenTile{
        display: inline-block;
    }
    .wideTile{
        width: calc(80% - 20px);
    }

}
@media screen and (max-width: 1000px) {
    .wideTile{
        width: calc(100% - 20px);
        height: auto;
    }
    .wideTileTitle{
        padding-bottom: 5px;
    }
}
@media screen and (max-width: 700px) {
    .tile{
        width: calc(80% - 20px);
        /* margin-left: calc(10%); */
        padding-bottom: 40px;
    }
    .sometimesHiddenTile{
        display: none;
    }
}
@media screen and (max-width: 550px) {
    .tile{
        width: calc(100% - 20px);
        /* margin-left: calc(10%); */
        padding-bottom: 40px;
    }
}