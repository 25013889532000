.storyEditor{
    width: calc(100% - 10px);
    margin-left: 5px;
    border: 1px solid black;
    background-color: rgba(255, 255, 255, 0.2);
    margin-bottom: 10px;   
    padding-bottom: 10px; 
    position: relative;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
}
.storyEditor textarea, input{
    color: black;
    margin-bottom: 0;
}
.storyTitle{
    cursor: pointer;
    padding-bottom: 10px;
    position: relative;
}
.storySection{
    width: 100%;
    border-bottom: 1px solid black;
    margin-bottom: 10px;    
    padding-bottom: 10px; 
    position: relative;
    padding: 10px;    
}