/* Message Box */

.formBox{
    width: 100%;
    border: 1px solid black;
    margin: 20px 0;
    padding: 20px;
}
.formBox input{
    border-radius: 2px;
}
.inputFull{
    margin-left: 5px;
    margin-right: 5px;
    width: calc(100% - 10px);
    /* For the text area */
    min-width: calc(100% - 10px);
    max-width: calc(100% - 10px);
}
.inputHalf{
    margin-right: 5px;
    margin-left: 5px;
    width: calc(50% - 10px);
    /* For the text area */
    min-width: calc(50% - 10px);
    max-width: calc(50% - 10px);
}
.formBox select {
    border-radius: 2px;
    margin-bottom: 20px;
}
.formBox textarea{
    height: 200px;
    border-radius: 2px;
}
.formBoxTitle{
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 20px;
}
.formSubmitButtons button{
    width: 48%;
    display: inline-block;
}
.formSubmittedBoxContainer{
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.formSubmittedBox{
    color: rgb(75, 178, 75);
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}