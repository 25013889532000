.facultyResearchTitle{
    
}
.facultyResearchTitle2{
    padding-bottom: 5px;
    margin-bottom: 20px;
}
.facultyResearchAuthorLine{
    /* padding-bottom: 10px; */
    font-size: 14px;
    font-weight: normal;
}