/* FAQ */

.faqArea{
    /* padding: 0px 60px 40px 60px;
    padding: 0px 0px 40px 0px; */
}
.faqContainer{
    padding-bottom: 40px;
}
.faqTitle{
    /* padding: 20px; */
    padding-top: 60px;
    padding-bottom: 20px;
}
.faqLine{
    padding: 20px;
    padding-bottom: 5px;
    overflow: hidden;
    border-bottom: 1px solid rgba(147, 147, 147, 0.754);
}
.faqLineTitle{
    text-align: center;
    font-size: 20px;
    padding-left: 40px;
    cursor: pointer;
    user-select: none;
    padding-bottom: 10px;
}
.faqLineContent{
    padding-left: 40px;
    line-height: 25px;
    text-align: left;
    max-height: 0;
    transition: 1000ms;
}
.faqLineContentOpen{
    max-height: 800px;
}
