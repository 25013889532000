.topNav{
    height: 80px;
    background-color: black;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 4px solid #fc0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
}
.logo{
    height: 60px;
    width: 280px;
    /* background-color: blue; */
    text-align: left;
    display: flex;
    align-items: center;
    color: white;
    font-size: 40px;
}
.logo img{
    /* width: 60px;
    margin-right: 20px; */
}
.topNavButtons{
    position: absolute;
    top: 30px;
    right: 40px;
}
.topNavButtons a{
    color: white;
    margin: 10px;
    transition: 1000ms;
    text-decoration: none;
    font-size: 16px;
    border-bottom: 2px solid rgba(255, 255, 255, 0);
    padding-bottom: 5px;
}
.topNavButtons a:hover{
    border-bottom: 2px solid #fc0;
}
.topNavButtonsMenu{
    display: none;   
    position: absolute;
    top: 12px;
    right: 20px;
    height: 50px;
    width: 50px;
    cursor: pointer;
}
.topNavButtonsMenuButtons{
    position: absolute;
    right: 0px;
    top: 80px;
    width: 100%;
    background-color: black;
    height: 0;
    transition: 1000ms;
    overflow: hidden;
    display: none;
}
.topNavButtonsMenuButtonsOpen{
    height: 380px;
}
.topNavButtonsMenuButtons a{
    color: white;
    text-decoration: none;
    display: block;
    padding: 20px 8px;
    transition: 1000ms;
}
.topNavButtonsMenuButtons a:hover{
    background-color: rgba(145, 145, 145, 0.37);
}
/* Borrom Nav */

.bottomNav{
    border-top: 4px solid rgb(231, 174, 3);;
    background-color: black;
    padding: 0 50px;
    box-sizing: border-box;
    width: 100%;
    min-height: 300px;
    padding-top: 40px;
    padding-bottom: 20px;
}
.bottomNavColumn{
    padding: 0px 20px;
    box-sizing: border-box;
    width: 33%;
    color: white;
    display: inline-block;
    /* background-color: blue; */
    vertical-align: top;
}
.bottomNavColumn .logo{
    display: inline-block;
}
.bottomNavColumnTitle{
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 10px;    
}
.bottomNavColumnLinks a{
    color: white;
    text-decoration: none;
    display: block;
    padding: 8px;
    transition: 500ms;
}
.bottomNavColumnLinks a:hover{
    background-color: rgba(145, 145, 145, 0.37);
}
@media screen and (max-width: 900px){
    .topNavButtons{
        display: none;
    }
    .topNavButtonsMenu{
        display: inline-block;
    }
    .topNavButtonsMenuButtons{
        display: inline-block;
    }
    .bottomNavColumn{
        width: 50%;
        padding-top: 10px;
    }
    .bottomNavLogoColumn{
        width: 100%;
    }
    .bottomNavLogoColumn img{
        width: 100%;
        height: 100px;
    }
    .bottomNavColumnTitle{
        padding-top: 20px;
    }
}
@media screen and (max-width: 650px){
    .bottomNav{
        padding-left: 0;
        padding-right: 0;
    }
}