.confirmation-box-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2); /* More transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.confirmation-box {
    width: 300px;
    background-color: rgba(87, 87, 90, 0.8);;
    border: 1px solid var(--secondary-color); /* Add border with secondary color */
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add box shadow */
}
.confirmation-box button{
    min-width: auto;    
}

.confirmation-message {
    text-align: center;
    margin-bottom: 20px;
    color: var(--text-color);
    font-size: 1.1em;
    line-height: 1.4;
}

.confirmation-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

.confirmation-button:hover{
    background-color: var(--background-color-secondary);
}

.cancel-button {
    background-color: var(--background-color);
    color: var(--text-color);
}

.cancel-button:hover {
    background-color: var(--hover-color);
}

.confirm-button {
    background-color: var(--primary-color);
    color: white;
    border-color: var(--primary-color);
}

.confirm-button:hover {
    background-color: var(--primary-hover-color);
}
