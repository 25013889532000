.authPage{
    text-align: center;
    background-color: rgb(249, 247, 244);
    display: flex;
    justify-content: center;
    align-items: center;
}
.studentPortalColumn{
    width: 500px;
    display: inline-block;
}
.studentPortalRow{
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    box-sizing: border-box;

}
.studentSigninLogo{
    background-color: black;
}
.studentPortalInputFieldsBox{
    background-color: white;
    box-shadow: 0 5px 10px rgba(67, 67, 67, 0.43);
    padding: 30px 0;
}
.studentPortalInputFieldsBoxTitle{
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
}
.studentPortalInputFieldsBox button{
    width: 80%;
    margin-bottom: 10px;
}
.signInMessage{
    font-size: 12px;
    padding: 10px;
    padding-top: 0;
    color: rgb(39, 0, 0);
    width: 70%;
    display: inline-block;
}
.loginBottomText{
    font-size: 12px;
    margin-top: 10px;
}
